import React, { useState } from 'react';
import GoogleMapReact, { Polyline } from 'google-map-react';

const apiKey = 'AIzaSyBHuzb3DSH5w0TCj63Ciwi-qGLOrlFpmbM';

const Marker = ({ text }) => (
  <div style={{ color: 'blue', fontSize: '20px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
    <span role="img" aria-label="Pin">📌</span> {text}
  </div>
);

const DashboardContent = () => {
  const [mapType, setMapType] = useState('roadmap');

  const toggleMapType = () => {
    setMapType(prevMapType => prevMapType === 'roadmap' ? 'satellite' : 'roadmap');
  };

  const path = [
    { lat: 41.876622, lng: -87.632782 },
    { lat: 41.972796, lng: -87.650252 },
    { lat: 41.888870, lng: -87.630530 },
    { lat: 41.853150, lng: -87.618530 },
  ];

  return (
    <div>
      <h1>Zentro Network Map</h1>
      <p>Network Edge Maps</p>

      <div style={{ height: '400px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey }}
          defaultCenter={path[0]}
          defaultZoom={11}
          options={{ mapTypeId: mapType }}
        >
          <Marker lat={path[0].lat} lng={path[0].lng} text="427L" />
          <Marker lat={path[1].lat} lng={path[1].lng} text="5030N" />
          <Marker lat={path[2].lat} lng={path[2].lng} text="353C" />
          <Marker lat={path[3].lat} lng={path[3].lng} text="350E" />
        </GoogleMapReact>
      </div>

      <button onClick={toggleMapType}>Toggle Map Type</button>
    </div>
  );
};

export default DashboardContent;