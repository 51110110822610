import React, { useState } from 'react';
import axios from 'axios';
import { styled } from '@mui/system';
import { TextField, Button, Typography } from '@mui/material';
import loginBackground from '../../assets/loginBackground.jpg';

const LoginPage = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const LoginForm = styled('form')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  zIndex: 1,
  marginTop: '4rem',
}));

const Heading = styled('h1')(({ theme }) => ({
  position: 'absolute',
  top: 'calc(50% - 15rem)',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'rgba(0, 0, 0, 0.6)',
  zIndex: 2,
  textAlign: 'center',
  width: '100%',
}));

const BackgroundImage = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: `url(${loginBackground})`,
  backgroundSize: 'cover',
  filter: 'blur(3px)',
  zIndex: -1,
}));

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('/api/login', { username, password });
      const { authenticated, returnedUsername, token } = response.data;

      if (authenticated) {
        // Successful login
        onLogin(authenticated, returnedUsername, token); // Call the onLogin function passed from the parent component
        console.log('Authentication successful');
      } else {
        // Authentication failed, display error message
        setError('Login failed! Please try again.');
      }
    } catch (error) {
      console.log('Error occurred during login:', error);
      setError('An error occurred during login. Please try again!');
    }
  };

  return (
    <LoginPage>
      <BackgroundImage />
      <Heading className="center">Zentro Inventory Portal</Heading>
      <LoginForm onSubmit={handleLogin}>
        <Typography variant="h5" gutterBottom>
          Please Login
        </Typography>
        <TextField
          type="text"
          value={username}
          placeholder="Username"
          onChange={(e) => setUsername(e.target.value)}
          margin="normal"
        />
        <TextField
          type="password"
          value={password}
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
        />
        {error && <Typography color="error">{error}</Typography>}
        <Button type="submit" variant="contained" color="info" size="large">
          Log in
        </Button>
      </LoginForm>
    </LoginPage>
  );
};

export default Login;
