import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Dashboard from './components/Dashboard/Dashboard';
import DashboardContent from './components/DashboardContent';
import EquipmentDatabase from './components/EquipmentDatabase';

const AppRoutes = ({ username, handleLogout }) => {
  return (
    <Routes>
      <Route path="/" element={<DashboardContent />} />
      <Route path="/equipment-database" element={<EquipmentDatabase />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
