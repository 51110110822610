import React, { useEffect, useState } from 'react';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import axios from 'axios';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchSessionData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/session', {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { authenticated, returnedUsername } = response.data;
      setAuthenticated(authenticated);
      setUsername(returnedUsername);
    } catch (error) {
      console.log('Error occurred while fetching session:', error);
      setAuthenticated(false);
      // Handle error if session retrieval fails
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSessionData();
  }, []);

  const handleLogin = (authenticated, returnedUsername, token) => {
    setAuthenticated(authenticated);
    setUsername(returnedUsername);
    setToken(token);
    localStorage.setItem('authenticated', authenticated);
    localStorage.setItem('username', returnedUsername);
    localStorage.setItem('token', token);
  };

  const handleLogout = () => {
    axios
      .post('/api/logout', {}, { withCredentials: true })
      .then(() => {
        setAuthenticated(false);
        setUsername('');
        setToken('');
        localStorage.removeItem('authenticated');
        localStorage.removeItem('username');
        localStorage.removeItem('token');
      })
      .catch(error => {
        console.log('Error occurred during logout:', error);
      });
  };

  if (loading) {
    return <div>Loading page...</div>;
  }

  if (!authenticated) {
    return (
      <div>
        <Login onLogin={handleLogin} />
      </div>
    );
  }

  return (
    <Router>
      <Dashboard username={username} handleLogout={handleLogout}>
      </Dashboard>
    </Router>
  );
}

export default App;
